@font-face {
  font-family: "Avenir";
  src: url(../public/fonts/Avenir.otf) format("truetype");
}

@font-face {
  font-family: "Avenir Bold";
  src: url(../public/fonts/Avenir_Bold.otf) format("truetype");
}

.App {
  text-align: center;
  color: #000b28;
  font-family: 'Avenir';
  
}
.App h1{
  font-family: 'Avenir Bold';
}
.header-logo{
  position:absolute;
  left:10vw;
  top:5vh;
  height:40px;
}
.general-container{
  width:100vw;
  min-height:100vh;
  height:100%;
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  padding:4vh 10vw;
  box-sizing: border-box;
}
.card{
  margin-top:40px;
  border:1px solid grey;
  border-radius:20px;
  padding:30px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:20px;
  font-size:20px;
  background-color: rgba(255, 255, 255, 0.5);
}
.card input{
  border-radius:50rem;
  padding: 12px 24px;
  width:500px;
  max-width:60vw;
  font-size:20px;
  font-family:'Avenir';
}
.card button{
  border-radius:50rem;
  padding: 12px 24px;
  background-color:#ff9933;
  font-size:20px;
  color:white;
  border:1px solid white;
  cursor:pointer;
  font-family:'Avenir';
}
.card button:hover{
  background-color:white;
  color:#ff9933;
  border-color:#ff9933;
}

@media(max-width:767px){
  .general-container{
    padding:12vh 10vw;
  }
  .App h1{
    text-align: left;
  }
}